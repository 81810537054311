.ql-editor{
    height:500px;
    font-size:16px;
}
.loading {
  position: absolute;
  top: 25%;
  right: 56%;
  z-index: 100;
}
p{
  margin-top: 0;
  margin-bottom: 0;
}