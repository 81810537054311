
nav{
    background: rgb(25,135,84);
    /*background: linear-gradient(180deg, rgba(25,135,84,1) 0%, rgba(11,51,32,1) 100%);*/
    height: 40px;
}
nav h1{
    color: white;
    font-size: 22px;
}
nav .logout{
    padding-top: 4px;
    font-size: 22px;
}
nav .logout:hover{
    color: #ccc;
}
nav .navsub{
    position:absolute;
    top:6px;
    color:white;
    margin-left:5px;
}
nav .navsub:hover{
    color: #ccc;
}
.navlogo{
    width:30px;
    border: #000 1px solid;
}
.user-name{
    color: white !important;
    padding-right:5px;
}
.color-white{
    color: #ddd !important;
}